import styled from 'styles/styled';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentTop = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentBottom = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  overflow: hidden;
`;

export const RowTop = styled.div`
  padding: 24px 15px 20px 15px;
`;

export const RowContent = styled.div`
  margin-bottom: 20px;
`;

export const RowBelt = styled.div`
  margin-bottom: 15px;
  &.lastRowBelt {
    margin-bottom: 20px;
  }
`;

export const Title = styled.h3`
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  color: #EFEFEF;
`;

export const LineText = styled.h4`
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: #000;
  min-height: 22px;
  background-color: #EFEFEF;
  width: 100%;
  line-height: 22px;
`;

export const TitleSecond = styled.h5`
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #EFEFEF;
  font-weight: 500;
`;

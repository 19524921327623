import styled from 'styles/styled';
import navDetail from '../../../assets/PageDetail/NavDetail.svg';
import imglogout from '../../../assets/Icon/icon_logout.svg';
import { StyledLink } from 'components/ui/StyledLink/StyledLink';

export const Wrapper = styled.div`
  min-height: 65px;
  width: calc(100% - 16px);
  max-width: 1440px;
  margin: 0 auto;
  @media (max-width: 1020px) {
    min-height: 50px;
    background-image: url(${navDetail});
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    padding: 0 10px;
    max-width: 700px;
  }
  @media (max-width: 720px) {
    max-width: 500px;
    @media (min-height: 650px) {
      position: fixed;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  @media (max-width: 450px) {
    background-size: 750px;
  }
  @media (max-width: 320px) {
    min-width: 320px;
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ColLeft = styled.div`
  width: calc(100% - 290px);
  min-height: 65px;
  background-image: url(${navDetail});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  box-shadow: inset -30px 0px 30px -25px #000000;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1020px) {
    width: 100%;
    min-height: 50px;
    box-shadow: none;
    background-image: none;
  }
`;

export const ColRight = styled.div`
  width: 282px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1020px) {
    display: none;
  }
`;

export const Col = styled.div`
  min-height: 10px;
  display: flex;
  align-items: center;
  @media (max-width: 1020px) {
    width: inherit;
  }
  &.center {
    justify-content: center;
  }
  &.menuCol {
    align-items: flex-end;
    justify-content: flex-end;
    @media (max-width: 1020px) {
      display: flex;
      align-items: center;
    }
    .menuWrap {
      @media (max-width: 1020px) {
        display: none;
      }
    }
    .btnLog {
      display: none;
      @media (max-width: 1020px) {
        display: flex;
      }
    }
  }
`;

export const PageContent = styled.div`
  width: 100%;
  min-height: 500px;
`;

export const BtnLogo = styled(StyledLink)`
  margin-left: 24px;
  display: inline-block;
  @media (max-width: 1300px) {
    margin-left: 15px;
  }
  @media (max-width: 1020px) {
    margin: 0;
  }
  .svgMobile {
    display: none;
    @media (max-width: 1020px) {
      display: block;
      svg {
        width: 48px;
      }
    }
  }
  .svgDesktop {
    display: block;
    @media (max-width: 1300px) {
      svg {
        width: 100px;
      }
    }
    @media (max-width: 1020px) {
      display: none;
    }
  }
`;

export const BtnLogout = styled(StyledLink)`
  font-size: 16px;
  font-weight: 700;
  color: #EFEFEF;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1020px) {
    font-size: 14px;
  }
`;

export const IconLogOut = styled.div`
  background-image: url(${imglogout});
  width: 13px;
  height: 16px;
  margin-left: 6px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

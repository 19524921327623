import styled from 'styles/styled';

export const Box = styled.div`
  background-color: #15191E;
  border-radius: 12px;
  width: 100%;
  padding: 15px 0;
  min-height: 327px;

  @media (max-width: 720px) {
    min-width: 225px;
    max-width: 460px;
    padding: 8px 0;
    height: 267px;
    position: fixed;
    bottom: 8px;
    left: 0;
    right: 0;
    z-index: 20;
    width: calc(100% - 90px);
    margin: 0 auto;
    min-height: initial;
  }
  @media (max-height: 500px) {
    position: relative;
    margin-top: 8px;
  }
  div {
    color: #EFEFEF;
  }
`;

export const Wrap = styled.div`
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 720px) {
    display: block;
  }
`;

export const Col = styled.div`
  max-width: 390px;
  width: 100%;
  button {
    width: 100%;
    min-height: 36px;
    @media (max-width: 720px) {
      min-height: 30px;
      border-radius: 8px;
    }
    &.SellDetails {
      background-color: #FF4B4A;
    }
    &.MarginButton {
      width: 50%;
    }
    &:disabled {
      background-color: #292B2E;
      color: #000;
    }
  }
  @media (max-width: 1330px) {
    max-width: 45%;
  }
  @media (max-width: 720px) {
    display: none;
    max-width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
  }
`;

export const MobileView = styled.div`
  display: none;
  max-width: 430px;
  width: 100%;
  position: relative;
  button {
    width: 100%;
    min-height: 45px;
    @media (max-width: 720px) {
      min-height: 30px;
      border-radius: 8px;
      height: 30px;
      font-size: 14px;
    }
    &.SellDetails {
      background-color: #FF4B4A;
    }
    &:disabled {
      background-color: #292B2E;
      color: #000;
    }
  }
  @media (max-width: 720px) {
    display: block;
    max-width: 100%;
    padding: 0 10px;
  }
`;

import React, {useEffect, useState} from 'react';
import { ButtonFilled } from 'components/ui/Button';

import {
  Wrap,
  TitleModal,
  TextModal,
  ButtonModal,
  WrapCenter,
} from './styled';

const ModalSmallInfo = () => {
  const [displayPopUp, setDisplayPopUp] = useState(true);

  const closePopUp = () => {
    localStorage.setItem('FeeInfoPopup', 'true')
    setDisplayPopUp(false);
  };

  useEffect(() => {
    let returningUser = localStorage.getItem("FeeInfoPopup");
    setDisplayPopUp(!returningUser);
  }, []);

  return (
    <>
      {displayPopUp && (
        <Wrap>
          <div>
            <WrapCenter>
              <TitleModal>Tournament information</TitleModal>
              <TextModal><b>0.1%</b> transaction fee and <b>0.1%</b> spread is added to each transaction</TextModal>
              <ButtonModal>
                <ButtonFilled type="button" onClick={closePopUp}>OK</ButtonFilled>
              </ButtonModal>
            </WrapCenter>
          </div>
        </Wrap>
      )}
    </>
  );
};

export default ModalSmallInfo;

import React from 'react';
import { TournamentCountdown } from '../TournamentCountdown/TournamentCountdown';
import { useSocket } from 'utils/hooks/useSocket';
import { formatCurrency } from 'utils';
import Cookies from 'js-cookie';
import { useWalletData } from 'context/WalletDataContext';
import { useTournament } from 'context/TournamentContext';
import TournamentRankingList from 'components/game/TournamentRankingList';

import {
  RowTop,
  Wrap,
  RowContent,
  RowBelt,
  Title,
  LineText,
  TitleSecond,
  ContentTop,
  ContentBottom,
} from './styled';

type Balance = {
  BTC: number;
  USDT: number;
};

type TournamentRankingData = [string, string][];

type TournamentDetails = {
  data?: {
    id?: number;
    attributes?: {
      name?: string;
      end_at?: string;
      start_at?: string;
      amount_start?: number;
      market_type?: any;
      trade_type?: string;
    };
  };
};

const TournamentRanking = () => {
  const { tournamentRanking, tournamentDetails } = useSocket() as { tournamentRanking: TournamentRankingData, tournamentDetails: TournamentDetails};
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const { tournamentStatus, tournamentPairList, joinedStatus } = useTournament();
  const tournamentType = tournamentDetails?.data?.attributes?.trade_type;
  const { totalMoneyString } = useWalletData();
  const userRanking = tournamentRanking.find(([user]) => user === username);
  const totalUSDT = tournamentType === "Margin" ? (userRanking ? parseFloat(userRanking[1]) : 0) : totalMoneyString;
  const tradingPair = tournamentPairList
  const isInvalidDataUSDT = isNaN(tournamentDetails?.data?.attributes.amount_start);

  return (
    <Wrap>
      <ContentTop>
        <RowTop>
          <Title>{tournamentDetails?.data?.attributes?.name}</Title>
        </RowTop>
        <RowContent>
          <RowBelt>
            {(tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'BEFORE_START') ? (
              <>
                {(tournamentStatus === 'BEFORE_START') ? (
                  <>
                    <TitleSecond>Starts in</TitleSecond>
                    <TournamentCountdown date={tournamentDetails?.data?.attributes?.start_at} />
                  </>
                ) :
                  <>
                    <TitleSecond>Time left</TitleSecond>
                    <TournamentCountdown date={tournamentDetails?.data?.attributes?.end_at} />
                  </>
               }
              </>
            ) :
              <>
                <TitleSecond>Time left</TitleSecond>
                <LineText>Finished</LineText>
              </>
            }
          </RowBelt>
          <RowBelt>
            <TitleSecond>Trading pair</TitleSecond>
            <LineText>{tradingPair.length === 0 ? `` : tradingPair?.length > 1 ? `Multi assets` : tradingPair[0]?.attributes?.name}</LineText>
          </RowBelt>
          <RowBelt className="lastRowBelt">
            {joinedStatus && (tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'BEFORE_START') ? (
              <>
              {!isInvalidDataUSDT && (
                <>
                  <TitleSecond>Total asset value</TitleSecond>
                  <LineText>{formatCurrency('0.00' !== totalUSDT ? totalUSDT : tournamentDetails?.data?.attributes.amount_start)} USDT</LineText>
                </>
              )}
              </>
            ) :
              <>
                <TitleSecond>Total asset value</TitleSecond>
                <LineText>--- USDT</LineText>
              </>
            }
          </RowBelt>
          <Title>Tournament <br />Ranking</Title>
        </RowContent>
      </ContentTop>
      <ContentBottom>
        <TournamentRankingList />
      </ContentBottom>
    </Wrap>
  );
};

export default TournamentRanking;

import styled from 'styles/styled';

export const Icon = styled.div<{ $src?: string; }>`
  width: 26px;
  height: 26px;
  overflow: hidden;
  border-radius: 100px;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.$src});
  @media (min-width: 900px) {
    width: 60px;
    height: 60px;
  }
`;

export const WrapTPSL = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  h3, p {
    display: none;
  }
  button {
    width: 100%;
  }
`;

export const WrapIcon = styled.div`
  width: 26px;
  height: 26px;
  margin: 6px;
  border-radius: 150px;
  overflow: hidden;
`;

export const Wrap = styled.div`
  width: calc(100% - 50px);
  float: right;
  padding-right: 10px;
  @media (max-width: 720px) {
    width: calc(100% - 45px);
  }
`;

export const Tab = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 10px 8px 0;
`;

export const Box = styled.div`
  border-radius: 14px;
  border: 1px solid #696969;
  min-height: 20px;
  overflow: hidden;
  margin-bottom: 8px;
`;

export const BoxRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RowInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
`;

export const RowTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  span {
    font-size: 10px;
    color: #EFEFEF;
    font-weight: 400;
  }
`;

export const InfoMargin = styled.span`
  color: #090B0E;
  background-color: #52D381;
  font-size: 13px;
  padding: 3px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-weight: 600;
  &.down_color {
    background-color: #FF4B4A;
  }
  &.up_color {
    background-color: #52D381;
  }
  @media (max-width: 400px) {
    font-size: 10px;
    padding: 2px 5px;
  }
`;

export const Leverage = styled.span`
  color: #090B0E;
  font-size: 12px;
  background-color: #DBB230;
  padding: 3px 6px;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-weight: 600;
  @media (max-width: 400px) {
    font-size: 10px;
    padding: 2px 5px;
  }
`;

export const BoxLeft = styled.div`
  background-color: #1D242B;
  display: flex;
  border-bottom-right-radius: 8px;
  justify-content: space-between;
`;

export const BoxRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2px 0;
  width: 50%;
  margin-bottom: 3px;
  span {
    font-size: 9px;
    color: #696969;
    font-weight: 400;
    margin-bottom: 1px;
  }
  p {
    @media (max-width: 400px) {
      font-size: 8px;
    }
  }
  h4 {
    @media (max-width: 400px) {
      font-size: 11px;
    }
  }
  &.up_color {
    h4, p {
      color: #52D381;
    }
  }
  &.down_color {
    h4, p {
      color: #FF4B4A;
    }
  }
`;

export const AmountInfo = styled.h4`
  font-weight: 500;
  color: #efefef;
  font-size: 13px;
`;

export const Percentages = styled.p`
  font-size: 9px;
  font-weight: 400;
  color: #efefef;
`;

export const CurrencyInfo = styled.h4`
  font-size: 10px;
  font-weight: bold;
  color: #efefef;
`;

export const BoxContent = styled.div`
  min-height: 104px;
  .button {
    width: 100%;
    background-color: #292B2E;
    color: #EFEFEF;
  }
`;

export const EditBoxWrap = styled.div`
  p {
    font-size: 12px;
    color: #efefef;
    line-height: 15px;
    text-align: center;
  }
  button {
    width: 50%;
  }
`;

export const BoxButton = styled.div`
  display: flex;
  justify-content: space-between;
  .button {
    width: calc(50% - 4px);
  }
`;


export const Col = styled.div`
  &.colLeft {
    width: 65%;
    padding-left: 8px;
  }
`;

export const ColRow = styled.div`
  width: 100%;
`;

export const SmallBox = styled.div`
  border: 1px dashed #EFEFEF;
  border-radius: 3px;
  background-color: #292B2E;
  min-height: 38px;
  width: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h5 {
    width: initial;
    margin: 0;
    &.down_color {
      color: #FF4B4A;
    }
    &.up_color {
      color: #52D381;
    }
    span {
      width: initial;
      margin: 0 8px 0 0;
    }
  }
`;

export const SmallText = styled.h4`
  font-size: 8px;
  font-weight: 400;
  color: #EFEFEF;
  margin-bottom: 2px;
`;

export const Text = styled.h5`
  font-weight: 400;
  margin-bottom: 2px;
  font-size: 8px;
  color: #EFEFEF;
  display: flex;
  width: 100%;
  span {
    font-weight: 300;
    color: #696969;
    text-align: right;
    width: 50%;
    margin-right: 5px;
  }
  p {
    width: 50%;
  }
`;

export const ColWrap = styled.div`
  display: flex;
  padding: 9px 6px 9px 0;
  justify-content: space-between;
  min-height: 75px;
  display: flex;
  align-items: center;
  &.editColWrap {
    justify-content: center;
    padding: 0px;
  }
`;

export const WrapListTransaction = styled.div`
  height: calc(100vh - 457px);
  min-height: 190px;
  max-height: 190px;
  padding-right: 10px;
  overflow-y: scroll;
`;

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Button = styled.button`
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: #696969;
  appearance: none;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  &.active {
    color: #EFEFEF;
    &:after {
      content: " ";
      position: absolute;
      bottom: -2px;
      width: 20px;
      left: 0;
      height: 2px;
      border-radius: 3px;
      background-color: #EFEFEF;
    }
  }
`;

export const RowContent = styled.div`
  display: flex;
  justify-content: space-between;
  .col_left {
    width: 60%;
  }
  .col_right {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    span {
      width: 66px;
      border-radius: 7px;
      font-size: 8px;
      font-weight: 700;
      padding: 3px;
      text-align: center;
      color: #696969;
      border: 1px solid #696969;
      &.up_color {
        color: #52D381;
      }
      &.down_color {
        color: #FF4B4A;
      }
    }
  }
`;

export const Amount = styled.h4`
  color: #EFEFEF;
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  span {
    font-weight: 300;
    color: #696969;
    min-width: 35px;
    margin-right: 5px;
    float: left;
    text-align: right;
  }
  &.down_color {
    color: #FF4B4A;
  }
`;

export const BoxProfit = styled.div`
  background-color: #090B0E;
  padding: 3px 8px;
  p {
    font-size: 9px;
    color: #696969;
    font-weight: 300;
    text-align: center;
  }
  h4 {
    font-size: 9px;
    color: #52D381;
    font-weight: 400;
    text-align: center;
  }
`;

export const ListPerformance = styled.ul`
  max-height: 220px;
  overflow-y: scroll;
  height: calc(100vh - 665px);
  @media (max-width: 720px) {
    height: 190px;
  }
  li {
    margin-right: 10px;
    padding: 8px 0;
    border-top: 1px solid #696969;
    &:first-child {
      border-top: none;
    }
  }
`;

export const RowText = styled.div`
  color: #EFEFEF;
  margin-bottom: 2px;
  font-size: 10px;
  font-weight: 400;
  &.down_state {
    color: #FF4B4A;
  }
  &.up_state {
    color: #52D381;
  }
  &.down_color {
    p {
      color: #FF4B4A;
      font-weight: bold;
    }
  }
  &.up_color {
    p {
      color: #52D381;
      font-weight: bold;
    }
  }
  &.detail_color {
    p {
      color: #DBB230;
      font-weight: bold;
    }
  }
  p {
    font-size: 8px;
  }
  span {
    font-weight: 300;
    color: #696969;
    min-width: 45px;
    font-size: 8px;
    margin-right: 5px;
    float: left;
    text-align: right;
  }
`;

import styled from 'styles/styled';

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;


export const WrapCenter = styled.div`
  background-color: #15191E;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  min-width: 390px;
  margin: 0 auto;
  @media (max-width: 720px) {
    min-width: initial;
    width: 380px;
    max-width: calc(100% - 80px);
  }
`;

export const TextModal = styled.p`
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  line-height: 18px;
  color: #EFEFEF;
  text-align: center;
  margin: 0 auto;
  padding: 10px 0 20px 0;
  max-width: 300px;
  @media (max-width: 720px) {
    font-size: 12px;
    padding: 5px 0 15px 0;
  }
  b {
    color: #52D381;
  }
`;

export const TitleModal = styled.h3`
  font-size: 20px;
  color: #EFEFEF;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

export const ButtonModal = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 100%;
  }
`;

import React, { useState, useEffect } from 'react';
import { useSocket } from 'utils/hooks/useSocket';
import { WalletDataProvider } from 'context/WalletDataContext';
import { TradeUpdateProvider } from 'context/TradeUpdateContext';
import { useTournament } from 'context/TournamentContext';
import { MobileView } from 'react-device-detect';
import FullPageLoad from 'components/ui/FullPageLoad';
import ModalSmallInfo from 'components/ui/ModalSmallInfo';
import GameWrapper from 'components/layout/GameWrapper';
import EndWrapper from 'components/layout/EndWrapper';
import TournamentRanking from 'components/game/TournamentRanking';
import BottomSection from 'components/game/BottomSection';
import GraphBox from 'components/game/GraphBox';
import BuySell from 'components/game/BuySell';
import ShortLong from 'components/game/ShortLong';
import EndPage from 'components/End/EndPage';
import MobileFlowBottom from 'components/MobileFlowBottom/MobileFlowBottom';

import {
  Wrapper,
  RowTop,
  ColLeft,
  Box,
  ColRight,
  TitleMobile,
  RwdContent,

} from './styled';

const Game = () => {
  const { socket } = useSocket();
  const { tournamentDetails, tournamentStatus } = useTournament();
  const tournamentType = tournamentDetails?.data?.attributes?.trade_type;
  const [isloadPage, setLoadPage] = useState<Promise<boolean | void> | boolean>(true);

  useEffect(() => {
    const timeout = setTimeout(() => setLoadPage(false), 1000)
    return () => clearTimeout(timeout);
  }, [])
  if (isloadPage) {
      return <FullPageLoad/>;
  };

  return (
    <TradeUpdateProvider>
      <GameWrapper>
        {socket?.connected && tournamentDetails && (
          <WalletDataProvider tournamentDetails={tournamentDetails}>
            <Wrapper>
              <RowTop>
                <ColLeft>
                  <Box className="boxDetails">
                    {tournamentStatus === 'FINISHED' &&
                      <EndWrapper>
                        <EndPage tournamentDetails={tournamentDetails} />
                      </EndWrapper>
                    }

                    {tournamentStatus === 'IN_PROGRESS' &&
                      <>
                        <RwdContent>
                          <ModalSmallInfo />

                          <MobileView>
                            <TitleMobile>{tournamentDetails?.data?.attributes?.name}</TitleMobile>
                          </MobileView>
                          <GraphBox tournamentDetails={tournamentDetails} />
                        </RwdContent>

                        {(tournamentType === 'Margin') ? (
                          <ShortLong tournamentDetails={tournamentDetails} />
                        ) :
                          <BuySell tournamentDetails={tournamentDetails} />
                        }
                      </>
                    }
                  </Box>
                </ColLeft>
                <ColRight>
                  <Box>
                    <TournamentRanking />
                  </Box>
                </ColRight>
              </RowTop>
              <BottomSection tournamentDetails={tournamentDetails} />
            </Wrapper>

            <MobileView>
              <MobileFlowBottom />
            </MobileView>

          </WalletDataProvider>
        )}
      </GameWrapper>
    </TradeUpdateProvider>
  );
}
export default Game;
